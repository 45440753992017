<template>
        <div type="text" class="tree-area-main" @click.prevent="show">
            <img src="@/assets/img/icons/gps.svg" class="mr-2" alt="">{{ cato && getName(cato) || $t('announce.deliveryaddress')}}
            <modal
                name="tree-selec-modal"
                :width="800"
                :height="700"
                :adaptive="true" 
            >
                <div class="modal__area">
                    <h3 class="text-center"> {{ $t("announce.deliveryaddress") }} </h3>
                    <p class="close" @click="hide"><i class="fas fa-times"></i></p>
                    <div class="tree-area">
                        <shops-tree-item v-for="item in cities_" :key="item.id" :shop_item="item" :selectCato="selectCato">

                        </shops-tree-item>
                    </div>
                </div>
            </modal>
        </div>
</template>

<script>
import shopsTreeItem from './shopsTreeItem';


export default {
    name: 'tree-selec-modal',
    components: {shopsTreeItem},
    props: ['cato'],
    data: () => ({
        childShow: false,
    }),
    computed: {
        cities_ () {
            return this.$store.getters.get_city_by_parent(0);
        },
        cityName() {
            if (this.$store.getters.get_cato) {
                return this.$store.getters.get_cato.name
            } else {
                return ''
            }
        }
    },
    mounted () {
        this.$store.commit('RESET_OPENED_CITIES')
    },
    methods: {
        show() {
            this.$modal.show('tree-selec-modal')
        },
        hide() {
            this.$modal.hide('tree-selec-modal')
        },
        selectCato(data) {
            this.cato = data;
            this.$emit('setCato', data);
        },
        getName(el) {
            let name;
            switch(this.$i18n.locale) {
                case 'kz':
                    name = el.nameKz;
                    break;
                default: 
                    name = el.nameRu;
            }
            return name;
        },
        showCities() {
            this.childShow = !this.childShow
        }
    }
}
</script>

<style lang="scss" scoped>
// .vm--modal{
//     overflow-y: scroll !important;
//     &::-webkit-scrollbar {
//         width: 7px;
//     }
// }
.modal__area {
    padding:30px;
    color: #222;
    background: #553DB4;
    text-align: left;
    min-height: 100%;
    
h3 {
        color: #fff;
        margin-bottom: 20px;
    }
}
.close {
    color: #fff;
    font-weight: normal;
}

.tree-area {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
}

</style>