<script>
    export default {
        name: 'shopsTreeItem',
        props: ['shop_item', 'selectCato'],
        data () {
            return {
                open: false,
                loading: false
            }
        },
        computed: {
            cities_ () {
                return this.$store.getters.get_city_by_parent(this.shop_item.id);
            },
            opened_cities () {
                return this.$store.state.plans.opened_cities;
            },
        },
        methods: {
            openCity () {

                if ( !this.open && !this.opened_cities.includes(this.shop_item.id) ) {
                    this.$store.commit("SET_OPENED_CITIES", this.shop_item.id);
                    this.getCities();    
                }
                this.open = !this.open;
            },
            set_id(data) {
                this.$store.commit('SET_CLICKED_CITY', data)
                this.selectCato(data);
                this.$modal.hide('tree-selec-modal')
            },
            getCities() {

                let params = {
                    entity: 'DictionaryItem',
                    filter: {
                        'parentId': this.shop_item.id
                    },
                    page: 0,
                    length: 1000,
                }
                this.$store.dispatch('GET_CITIES_CHILDREN', params)
            },
            getName(el) {
                let name;
                switch(this.$i18n.locale) {
                    case 'kz':
                        name = el.nameKz;
                        break;
                    default: 
                        name = el.nameRu;
                }
                return name;
            }
        }
    }
</script>

<template>
    <div class="tree-box">
        <div class="tree-box__header">
            <button 
                class="btn btn__purple" 
                @click="openCity"
                v-if="shop_item.noLeaf"
            >
                {{ open ? '-' : '+' }}
            </button>

            <button v-else class="btn btn__file">
                <i class="far fa-file"></i>
            </button>

            <p @click="set_id(shop_item)">{{getName(shop_item)}}</p>
        </div>
        <div v-if="open && cities_" class="tree-box__child">

            <shops-tree-item 
                v-for="item in cities_" 
                :key="item.id" 
                :shop_item="item"
                :selectCato="selectCato"
            >
            </shops-tree-item>  
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tree-box {
    margin-bottom: 10px;
    position: relative;
    &__header {
        display: flex;
        position: relative;
        align-items: center;
        background: #eee;
        margin-bottom: 10px;
        &::before {
            content: "";
            position: absolute;
            top: 20px;
            left: -20.5px;
            width: 15px;
            height: 1px;
            background: #ccc;
        }
        p {
            width: 100%;
            padding:7px 0 0 20px;
            height: 38px;
            align-content: center;
            cursor: pointer;
            margin-bottom: 0;
            &:hover {
                background: #bbb;
            }
        }
    }
    &__child {
        padding-left: 20px;
        margin-left: 15px;
        margin-bottom: 10px;
        border-left: 1px solid #ccc;
        &::before {
            content: "";
            width: 1px;
            height: 17px;
            position: absolute;
            background: #fff;
            bottom: 0;
            left: 15px;
        }
    }
}
.btn__purple{
    background: #553DB4;
    color: #fff;
    min-width: 35px;
    outline: none;
}

.btn__file {
    background: #ccc;
}
</style>