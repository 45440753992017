<template>
    <div class="shops-modal">
        <modal
        :name="boxName"
        :width="boxWidth"
        :height="boxHeight"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="text__area" v-if="plan">
                <h4 class="text-center"> {{ translateObject(plan, 'nameRu', 'nameKz') }} </h4>

                <p class="close" @click="hide"><i class="fas fa-times"></i></p>

                <div class="shops-modal__content">
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.source") }} </div>
                        <div class="col-sm-7 col-12"> 
                            <a target="blank" :href="getLink"> {{ translateObject(plan.system, 'nameRu', 'nameKz') }} </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12">№</div>
                        <div class="col-sm-7 col-12"> {{ plan.number }} </div>
                    </div>
                    
                    <div class="row" v-if="plan.monthNameRu">
                        <div class="col-sm-5 col-12"> {{ $t("plan.plandate") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.monthNameRu | monthName }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.deliverydate") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.supplyDateRu }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.tru") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.henstru15Id }} </div>
                    </div>
                    <div class="row" v-if="plan.methodTrade">
                        <div class="col-sm-5 col-12"> {{ $t("plan.dMethodTrade") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(plan.methodTrade, 'nameRu', 'nameKz') }} </div>
                    </div>
                    <template v-if="plan.customerBin">
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.customerbin") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.customerBin.biniin }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.customer") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(plan.customerBin, 'nameru', 'namekz') }} </div>
                    </div>
                    </template>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.description") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(plan, 'descriptionRu', 'descriptionKz') }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.deliveryaddress") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.deliveryAddress }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.deliverykato") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.deliveryKato }} </div>
                    </div>
                    <div class="row" v-if="plan.advance">
                        <div class="col-sm-5 col-12"> {{ $t("plan.advance") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.advance }} ( {{ plan.sum * plan.advance / 100 | sum }} ) </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.finyear") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.finYear }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.price") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.price | sum }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.volume") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.count }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("announce.hmkei") }} </div>
                        <div class="col-sm-7 col-12"> {{ translateObject(plan.hmkeiId, 'nameRu', 'nameKz') }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("plan.sum") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.sum | sum }} </div>
                    </div>
                    <div class="row" v-if="plan.sumYear1">
                        <div class="col-sm-5 col-12"> {{ $t("plan.sumyear1") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.sumYear1 | sum }} </div>
                    </div>
                    <div class="row" v-if="plan.sumYear2">
                        <div class="col-sm-5 col-12"> {{ $t("plan.sumyear2") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.sumYear2 | sum }} </div>
                    </div>
                    <div class="row" v-if="plan.sumYear3">
                        <div class="col-sm-5 col-12"> {{ $t("plan.sumyear3") }} </div>
                        <div class="col-sm-7 col-12"> {{ plan.sumYear3 | sum }} </div>
                    </div>
                </div>

                <button class="btn btn-close" @click="hide"> {{ $t("general.close") }} </button>
            </div>
        </modal>
    </div>
</template>

<script>
import translateMix from '@/mixins/translate'

    export default {
        name: 'plan-modal-box',
        mixins: [
            translateMix
        ],
        props: {
            boxName: {
                type: String,
                required: true
            },
            boxWidth: {
                type: Number,
                default: 1200
            },
            boxHeight: {
                default: 'auto'
            },
            plan: {
                type: Object
            }
        },
        data: () => ({
        }),
        methods: {
            show() {
                this.$modal.show(this.boxName);
            },
            hide() {
                this.$modal.hide(this.boxName)
            }
        },
        computed: {
            getLink() {
                const url = this.plan.system.planUrl
                // const newUrl = url.replace('@@@/$$$', `${this.plan.externalId}/${this.plan.efcPlanActId}`)
                const newUrl = url && url.replace("@@@", this.plan.externalId).replace("$$$", this.plan.efcPlanActId);
                return newUrl
            }
        },
        filters: {
            monthName(num) {
                let month = +num
                month = month - 1

                var arr=['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь', 'Ноябрь','Декабрь',]
                
                if(month > 12) {
                    return ''
                } else {
                    return arr[month]
                }
            }
        }
    }
</script>   

<style lang="scss" scoped>
.shops-modal {
    position: relative;
    z-index: 9999;

    &__content{
        margin: 20px 0;
        // border: 1px solid #ccc;
        border: 1px solid rgb(231, 231, 231);
        border-radius: 5px;
        .row {
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid rgb(231, 231, 231);
            &:first-child{
                a {
                    display: inline;
                    color: #553DB4;
                }
            }
            &:last-child{
                border-bottom: none;
            }
            .col-12 {
                padding: 12px 15px;
                @media(max-width: 575px) {
                    text-align: center;
                }
            }
            .col-sm-5 {
                border-right: 1px solid #ccc;
                text-align: right;
                @media(max-width: 575px) {
                    text-align: center;
                    border-right: none;
                }
            }
            &:nth-child(odd) {
                background: rgb(241, 241, 241);
            }
        }
    }
}
.text__area {
    padding: 50px;
}
.btn-close {
    background: #FFAD00;
    display: block;
    color: #fff;
    width: 300px;
    margin: 0 auto;
}
    
</style>