export default {

    methods: {
        getFieldName(name) {
            let value;
            switch(this.$i18n.locale) {
                case 'kz':
                    value = name+'Kz';
                    break;
                default:
                    value = name+'Ru';
            }
            return value;
        },
        translate(rus, kaz) {
            this.$i18n.locale === 'kz' && kaz ? kaz : rus;
        },
        translateObject(object, rus, kaz) {
            return object && (this.$i18n.locale === 'kz' && object[kaz] ? object[kaz] : object[rus]);
        }
    }
}